<template>
    <div>
        <div class="bg-gray" v-if="!isLoaded">
            <Loader />
        </div>
        <div v-else id="bookFace">
            <div class="top" ref="top">
                <router-link :to="{name: 'OtherUserProfile', params: {id: bookface.user_id}}" v-if="type === 'user' && bookface.user.id != this.my_id" class="title user">
                    <img :src="plainUrl + this.bookface.user.img_url" alt="">
                    {{this.bookface.user.username}}
                </router-link>

                <router-link :to="{name: 'UserProfile'}" v-else-if="type === 'user' && bookface.user.id == this.my_id" class="title user">
                    <img :src="plainUrl + this.bookface.user.img_url" alt="">
                    Twój #Bookface
                </router-link>

                <router-link :to="{name: 'BooksDetails', params: {id: bookface.book_id}}" v-if="type === 'book'" class="title book">
                    <img :src="coverUrl + this.bookface.book.image" alt="">
                    {{this.bookface.book.tytul.substring(0, 18)}}{{this.bookface.book.tytul.length > 18 ? '...' : ''}}
                </router-link>

                <div class="icons">
                    <div v-if="bookface.user.id == this.my_id" id="delete" @click="togglePopup(true)">
                        <i class="far fa-trash-alt"></i>
                    </div>

                    <div v-if="bookface.user.id == this.my_id" id="share">
                        <a @click="share(bookfaceUrl + bookface.image)">
                            <i class="far fa-paper-plane"></i>
                        </a>
                    </div>

                    <div id="back" @click="useBackButton">
                        <i class="fas fa-times"></i>
                    </div>
                </div>
            </div>
            <div class="image-wrapper" :style="{backgroundImage: 'url(' + bookfaceUrl + this.bookface.image + ')'}"></div>
        </div>

        <ModalSlot title="Usuwanie bookface" v-if="isPopupShown" @close-modal="togglePopup(false)">
            <div class="text-center mb-4 modal-main-title">Czy na pewno chcesz usunąć ten Bookface?</div>

            <button class="btn-black-full w-100 mt-3 mb-1" @click="deleteBookface">Usuń</button>
            <button class="btn-black-full w-100 mt-3 mb-1" @click="togglePopup(false)">Anuluj</button>
        </ModalSlot>
    </div>
</template>
<script>

import Loader from '../components/Loader'
import ModalSlot from '../components/ModalSlot'
export default {
  name: 'Bookface',
  components: {
    Loader,
    ModalSlot
  },
  props: {
    bookface_id: [Number, String],
    type: String
  },
  data () {
    return {
      isLoaded: false,
      bookface: null,
      isPopupShown: false
    }
  },
  computed: {
    my_id () { return this.$store.state.userData.userId }
  },
  async created () {
    await this.$https.get('/bookface/get-bookface/' + this.bookface_id, {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(res => {
        this.bookface = res.data.response
        this.isLoaded = true
      })
      .catch(error => {
        console.log('', error)
      })
  },
  mounted () {
  },
  watch: {
    '$route' (to, from) {

    }
  },
  methods: {
    useBackButton () {
      this.$router.go(-1)
    },
    share (url) {
      window.location.href = 'gonative://share/sharePage?url=' + encodeURIComponent(url)
    },
    deleteBookface () {
      const data = new FormData()
      data.append('bookface_id', this.bookface_id)
      data.append('user_id', this.bookface.user.id)
      this.$https.post('/bookface/delete-bookface', data, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(res => {
          if (res.data.response === 'success') {
            this.$router.go(-1)
          }
        })
        .catch(error => {
          console.log('', error)
        })
    },
    togglePopup (value) {
      this.isPopupShown = value
    }
  }
}
</script>
<style lang="scss" scoped>
    .loader-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 500;
        background: rgba(255,255,255,0.9);
    }

    #bookFace{
        background: #1f1f1f;
        min-height: 100vh;
        width: 100vw;
        color: #fff;

        .top{
            padding: 20px 27px 20px;
            width: 100%;
            height: 13vh;
            z-index: 50;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            a{
                color: #ffffff;
            }
            #back{
                z-index: 20;
                color: white;
                font-size: 28px;
            }

            #delete{
                z-index: 20;
                color: white;
                font-size: 20px;
                margin-right: 20px;
            }

            #share{
                z-index: 20;
                color: white;
                font-size: 20px;
                margin-right: 20px;
            }

            .title{
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                flex: 1;
                max-height: 100%;
                display: flex;
                align-items: center;
                font-weight: 300;

                &.user{
                    img{
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                        margin-right: 12px;
                    }
                }

                &.book{
                        img {
                            width: 30px;
                            margin-right: 12px;
                        }
                }
            }

            .icons{
                padding-left: 30px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }

        .image-wrapper{
            position: relative;
            height: 87vh;
            background-size: 100%;
            background-repeat: no-repeat;
            overflow: hidden;
        }

        .bookface-share{
            background: transparent url('../assets/images/send-icon.svg') no-repeat center;
            background-size: cover;
            width: 20px;
            height: 20px;
            margin: 0 20px 0 0;
        }

    }
</style>
